import { AboutHero, Layout, SecondRow } from "../../Components";

function AboutUs() {
  return (
    <Layout>
      <AboutHero />
      <SecondRow />
      {/* <Vision /> */}
    </Layout>
  );
}

export default AboutUs;
